// @TODO: Fill default values once business share it with team.
import type { Locale } from '@/entities/routerBridge';
import type { SeoSettings } from './types';

const en = {
	title: 'PC Health: Prescription Management | Pharmacy Services',
	description:
		"From Managing your Prescriptions Online to Booking Pharmacy & Healthcare Appointments, it's all in one place with PC Health. Learn More Today.",
	robots: {
		metaRobotsIndex: true,
		crawlerFollow: true,
	},
	canonicalUrl: 'https://www.pchealth.ca/en/',
	additionalLinkTags: [
		{
			rel: 'icon',
			href: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/digital-pharmacy/images/shared/favicon-32x32.png',
			size: '32x32',
		},
		{
			rel: 'apple-touch-icon',
			href: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/digital-pharmacy/images/shared/apple-touch-icon.png',
			sizes: '180x180',
		},
		// https://web.dev/learn/pwa/web-app-manifest/
		{
			rel: 'manifest',
			href: '/site.webmanifest',
		},
	],
};

const fr = {
	...en,
	title: 'PC Santé : Gestion des ordonnances | Services pharmaceutiques',
	description:
		'De la gestion de vos ordonnances en ligne à la prise de rendez-vous en pharmacie et en soins de santé, tout se trouve au même endroit avec PC Santé. Apprenez-en plus dès aujourd’hui.',
	canonicalUrl: 'https://www.pchealth.ca/fr/',
	additionalLinkTags: [
		...en.additionalLinkTags.filter((tag) => tag.rel !== 'manifest'),
		{
			rel: 'manifest',
			href: '/site_fr.webmanifest',
		},
	],
};

// These are the default SEO values which will be used inside `_app.tsx`.
// These will also act as a fallback incase contentful query fails.
export const defaultSeoValues: Record<Locale, SeoSettings> = {
	en,
	fr,
};
