import type { Locale } from '@/entities/routerBridge';
import { Button, ButtonGroup, Flex, Link, Spacer } from '@chakra-ui/react';
import { CheckboxCard } from '@ldfeplatform/drx-component-library.ui.atoms.checkbox-card';
import { InputField } from '@ldfeplatform/drx-component-library.ui.atoms.input-field';
import { useTranslation } from 'next-i18next';
import { type FunctionComponent, useEffect, useReducer, useRef } from 'react';
import type { StepErrors } from 'utilities/profile';

type ControlName = 'firstName' | 'lastName' | 'preferredName' | 'privacyPolicy' | 'termAndConditions';

export type Step1Data = {
	firstName: string;
	preferredName: string;
	lastName: string;
	privacyPolicy: boolean;
	termAndConditions: boolean;
};

export type Step1FormProps = {
	locale: Locale;
	isEditMode?: boolean;
	step1Data: Step1Data;
	onStepDataUpdated: (data: Step1Data, isValid: boolean) => void;
	onSubmit: () => void;
	errorText: StepErrors;
};
type StateShape = {
	controls: Record<ControlName, { validated: boolean; isInvalid: boolean; isRequired: boolean }>;
	stepData: Step1Data;
	isStepValid: boolean;
};

type ValidationAction<T extends Step1Data = Step1Data, K extends ControlName = ControlName> = {
	name: K;
	value: T[K];
};

function validateReducer(state: StateShape, { name, value }: ValidationAction): StateShape {
	const newState = {
		...state,
		controls: {
			...state.controls,
			[name]: {
				...state.controls[name],
				isInvalid: !value,
				validated: !!value,
			},
		},
		stepData: {
			...state.stepData,
			[name]: value,
		},
	};
	const isStepValid = Object.values(newState.controls).every(({ isRequired, validated }) => {
		return isRequired ? validated : true;
	});
	return {
		...newState,
		isStepValid,
	};
}

const getInitialState = (stepData: Step1Data) => ({
	controls: {
		firstName: { validated: false, isInvalid: false, isRequired: true },
		lastName: { validated: false, isInvalid: false, isRequired: true },
		preferredName: { validated: false, isInvalid: false, isRequired: false },
		privacyPolicy: { validated: false, isInvalid: false, isRequired: true },
		termAndConditions: { validated: false, isInvalid: false, isRequired: true },
	},
	stepData,
	isStepValid: false,
});

export const Step1Form: FunctionComponent<Step1FormProps> = ({
	step1Data,
	onStepDataUpdated,
	onSubmit,
	isEditMode,
	errorText,
}) => {
	const { t } = useTranslation('createProfile');

	const [{ stepData, isStepValid }, dispatch] = useReducer(validateReducer, getInitialState(step1Data));

	function onValueChanged(name: ControlName) {
		return (value: Step1Data[ControlName]) => dispatch({ name, value });
	}

	useEffect(() => onStepDataUpdated(stepData, isStepValid), [stepData, isStepValid, onStepDataUpdated]);

	const { firstName, preferredName, lastName, privacyPolicy, termAndConditions } = t('step1', {
		returnObjects: true,
	}) as any;

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (evt) => {
		evt.preventDefault();
		onSubmit();
	};

	const autoFocusRef = useRef<HTMLInputElement>();
	useEffect(() => autoFocusRef.current?.focus(), [autoFocusRef]);

	return (
		<form onSubmit={handleSubmit}>
			<Flex flexDirection="column" gap={3}>
				<InputField
					ref={autoFocusRef}
					type={'text'}
					{...firstName}
					errorText={errorText?.firstName}
					isRequired={true}
					onInputChange={onValueChanged('firstName')}
					value={stepData.firstName}
					data-testid="first-name-input"
				/>
				<InputField
					type={'text'}
					{...preferredName}
					isRequired={false}
					onInputChange={onValueChanged('preferredName')}
					value={stepData.preferredName}
					data-testid="preferred-name-input"
				/>
				<InputField
					type={'text'}
					{...lastName}
					errorText={errorText?.lastName}
					isRequired={true}
					onInputChange={onValueChanged('lastName')}
					value={stepData.lastName}
					data-testid="last-name-input"
				/>
				{!isEditMode && (
					<>
						<Spacer height={3} />
						<CheckboxCard
							{...privacyPolicy}
							isChecked={step1Data.privacyPolicy}
							isRequired={true}
							onCheckedChanged={onValueChanged('privacyPolicy')}
							errorText={errorText?.privacyPolicy}
							helpText={
								<Link
									href={privacyPolicy.link}
									textStyle="common03.regular"
									color="uiBlue"
									textDecoration="underline"
									isExternal
								>
									{privacyPolicy.helpText}
								</Link>
							}
						/>
						<CheckboxCard
							{...termAndConditions}
							isChecked={step1Data.termAndConditions}
							isRequired={true}
							onCheckedChanged={onValueChanged('termAndConditions')}
							errorText={errorText?.termAndConditions}
							helpText={
								<Link
									href={termAndConditions.link}
									textStyle="common03.regular"
									color="uiBlue"
									textDecoration="underline"
									isExternal
								>
									{termAndConditions.helpText}
								</Link>
							}
						/>
						<ButtonGroup
							display="flex"
							justifyContent="flex-end"
							flexDir={{ base: 'column-reverse', md: 'row' }}
							spacing={2}
						>
							<Button type="submit" h={12} minW={{ sm: '100%', md: 140 }} px={8} py={3}>
								{t('cta.next')}
							</Button>
						</ButtonGroup>
					</>
				)}
			</Flex>
		</form>
	);
};
