import type { Locale } from '@/entities/routerBridge';
import {
	Environment,
	Language,
	PcidAuth,
	explicitLogoutFunction,
	implicitLogoutFunction,
	type LogoutRequest,
	type PcidProviderConfig,
} from '@pcid/web-sdk';
import type { Nominal } from '../../../types';
import { createSession, deleteSession } from './midtier';

const PcidRelyingParty = 'pc-health-web';

const parseLocaleToLanguage = (locale: Locale) => {
	switch (locale) {
		case 'fr':
			return Language.FR;
		case 'en':
		default:
			return Language.EN;
	}
};

const getRedirectUrl = (lang: Locale, baseUrl: string) => `${baseUrl}${lang}/authCallback/`;

const normalizeRedirectUrl = (slug?: string): RedirectUrl => {
	if (!slug) {
		return '/' as RedirectUrl;
	}

	if (!slug.startsWith('/')) {
		return `/${slug}` as RedirectUrl;
	}

	return slug as RedirectUrl;
};

const getTokenScope = (deploymentEnvironment: Environment) => {
	const PROD_SCOPE = 'api.loblaw.digitalcustomer-basic';
	const NON_PROD_SCOPE = 'lowers.api.loblaw.digitalcustomer-basic';
	return deploymentEnvironment === Environment.PRODUCTION ? PROD_SCOPE : NON_PROD_SCOPE;
};

export type RedirectUrl = Nominal<string, 'RedirectUrl'>;

export type GetAuthFxParams = {
	baseUrl: string;
	clientId: string;
	clientSecret: string;
	customProps?: Record<string, string>;
	deploymentEnvironment: Environment;
	locale: Locale;
};

export type LogoutFxParams = {
	baseUrl: string;
	clientId: string;
	clientSecret: string;
	deploymentEnvironment: Environment;
	explicit: boolean;
	idToken?: string;
	locale: Locale;
	redirectUrl?: string;
};

export type HandleSignInFxParams = {
	code: string;
	state: string;
	auth: PcidAuth;
};

export const getAuth = async ({
	customProps,
	locale,
	clientId,
	clientSecret,
	baseUrl,
	deploymentEnvironment,
}: GetAuthFxParams) => {
	const auth = new PcidAuth({
		scope: getTokenScope(deploymentEnvironment),
		addOfflineAccessToScopes: false,
		keepMeSignedIn: true,
		showIsThisYouPrompt: true,
		pkceFlow: true,
		clientId,
		clientSecret,
		stateParams: {
			relyingParty: PcidRelyingParty,
			language: parseLocaleToLanguage(locale),
			customProps,
		},
		redirectUrl: getRedirectUrl(locale, baseUrl),
		environment: deploymentEnvironment,
	} satisfies PcidProviderConfig);

	return auth;
};

export const logout = async ({
	explicit,
	clientId,
	clientSecret,
	deploymentEnvironment,
	idToken,
	redirectUrl,
	locale,
	baseUrl,
}: LogoutFxParams): Promise<RedirectUrl> => {
	await deleteSession();
	console.log({ baseUrl, locale, redirectUrl: normalizeRedirectUrl(redirectUrl) });
	if (!idToken) {
		return normalizeRedirectUrl(redirectUrl);
	}
	const logoutRequest: LogoutRequest = {
		clientId,
		clientSecret,
		idToken,
		redirectUri: `${baseUrl}${locale}${normalizeRedirectUrl(redirectUrl)}`,
	};
	if (explicit) {
		const redirectUrl = explicitLogoutFunction(deploymentEnvironment, logoutRequest);
		return (redirectUrl ?? '/') as RedirectUrl;
	} else {
		const logoutResponse = await implicitLogoutFunction(deploymentEnvironment, logoutRequest);
		return (logoutResponse.redirectUri ?? '/') as RedirectUrl;
	}
};

export const handleSignIn = async ({ code, state, auth }: HandleSignInFxParams) => {
	const tokens = await auth.handleSignIn(code, state);
	if (tokens === null) {
		throw new Error('code or state was missing');
	}

	await createSession(tokens);

	let parsedState: unknown;
	try {
		parsedState = JSON.parse(atob(state));
	} catch (ex) {
		console.error(ex);
	}
	if (
		parsedState instanceof Object &&
		'customProps' in parsedState &&
		parsedState.customProps instanceof Object &&
		'returnUrl' in parsedState.customProps &&
		typeof parsedState.customProps.returnUrl === 'string'
	) {
		return parsedState.customProps.returnUrl.toString() as RedirectUrl;
	}
	return '/' as RedirectUrl;
};
