import type { LanguageProps } from '@/entities/routerBridge';
import { $isFrench, $langCode, $locale, $localeCode } from '@/entities/routerBridge';
import { useUnit } from 'effector-react';

export const useLanguage = (): LanguageProps => {
	const [localeCode, langCode, locale, isFrench] = useUnit([$localeCode, $langCode, $locale, $isFrench]);

	return {
		locale,
		langCode,
		localeCode,
		isFrench,
	};
};
