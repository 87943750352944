import { useRouter } from 'next/router';
import { useEffect, type FunctionComponent } from 'react';
import type { LanguageCode, Locale, LocaleCode } from './model';

import { push, setIsFrench, setLangCode, setLocale, setLocaleCode, setRoute } from './model';

export type LanguageProps = {
	isFrench: boolean;
	locale: Locale;
	langCode: LanguageCode;
	localeCode: LocaleCode;
};

export const getLocaleDetails = (locale: string | undefined): LanguageProps => {
	const isFrench = locale === 'fr';
	return {
		isFrench,
		locale: isFrench ? locale : 'en',
		langCode: isFrench ? 'fra' : 'eng',
		localeCode: isFrench ? 'fr-CA' : 'en-CA',
	};
};

export const RouterBridge: FunctionComponent = () => {
	const router = useRouter();

	useEffect(() => {
		const subscription = push.watch(({ url, as, options }) => router.push(url, as, options));

		return () => {
			subscription.unsubscribe();
		};
	}, [router]);

	useEffect(() => {
		setRoute(router.asPath);
	}, [router.asPath]);

	useEffect(() => {
		const { isFrench, locale, langCode, localeCode } = getLocaleDetails(router.locale);
		setLocale(locale);
		setLangCode(langCode);
		setLocaleCode(localeCode);
		setIsFrench(isFrench);
	}, [router.locale]);

	return <></>;
};
