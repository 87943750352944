import type { Locale } from '@/entities/routerBridge';
import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { DobInput } from '@ldfeplatform/drx-component-library.ui.atoms.dob-input';
import type { DobRef } from '@ldfeplatform/drx-component-library.ui.atoms.dob-input/dob-input';
import { Dropdown } from '@ldfeplatform/drx-component-library.ui.atoms.dropdown';
import { InputField } from '@ldfeplatform/drx-component-library.ui.atoms.input-field';
import { MultiSelect } from '@ldfeplatform/drx-component-library.ui.molecules.multi-select';
import { useTranslation } from 'next-i18next';
import { useEffect, useReducer, useRef, type FunctionComponent } from 'react';
import type { StepErrors } from 'utilities/profile';

type ControlName =
	| 'dob'
	| 'sexAtBirth'
	| 'genderMultiSelect'
	| 'genderCustomOption'
	| 'pronounsMultiSelect'
	| 'pronounsCustomOption';

export type Step2Data = {
	dob: Date | undefined;
	sexAtBirth: string;
	genderMultiSelect: string;
	genderCustomOption: string;
	pronounsMultiSelect: string;
	pronounsCustomOption: string;
};

export type Step2FormProps = {
	locale: Locale;
	step2Data: Step2Data;
	backButtonText: string;
	isSubmitButtonLoading?: boolean;
	submitButtonText?: string;
	isEditMode?: boolean;
	errorText: StepErrors;
	onStepDataUpdated: (data: Step2Data, isValid: boolean) => void;
	onSubmit: () => void;
	onBackButtonClick: () => void;
};

type StateShape = {
	controls: Record<ControlName, { validated: boolean; isInvalid: boolean; isRequired: boolean }>;
	stepData: Step2Data;
	isStepValid: boolean;
};

type ValidationAction<T extends Step2Data = Step2Data, K extends ControlName = ControlName> = {
	name: K;
	value: T[K];
	validationResult?: boolean;
};

const isValueValid = ({ name, value, validationResult }: ValidationAction) => {
	const fieldsWithValidatedResult = ['dob'];
	if (fieldsWithValidatedResult.includes(name)) {
		return validationResult && value !== undefined;
	}
	return value !== '';
};

function validateReducer(state: StateShape, { name, value, validationResult }: ValidationAction): StateShape {
	const newState = {
		...state,
		controls: {
			...state.controls,
			[name]: {
				...state.controls[name],
				isInvalid: value === '',
				validated: isValueValid({ name, value, validationResult }),
			},
		},
		stepData: {
			...state.stepData,
			[name]: value,
		},
	};
	const isStepValid = Object.values(newState.controls).every(({ isRequired, validated }) => {
		return isRequired ? validated : true;
	});

	return {
		...newState,
		isStepValid,
	};
}

const getInitialState = (stepData: Step2Data) => ({
	controls: {
		sexAtBirth: { validated: false, isInvalid: false, isRequired: true },
		genderMultiSelect: { validated: false, isInvalid: false, isRequired: false },
		genderCustomOption: { validated: false, isInvalid: false, isRequired: true },
		pronounsMultiSelect: { validated: false, isInvalid: false, isRequired: false },
		pronounsCustomOption: { validated: false, isInvalid: false, isRequired: true },
		dob: { validated: false, isInvalid: false, isRequired: true },
	},
	stepData,
	isStepValid: false,
});

export const Step2Form: FunctionComponent<Step2FormProps> = ({
	step2Data,
	backButtonText,
	isSubmitButtonLoading,
	submitButtonText,
	isEditMode,
	errorText,
	onStepDataUpdated,
	onSubmit,
	onBackButtonClick,
}) => {
	const { t } = useTranslation('createProfile');

	const [{ stepData, isStepValid }, dispatch] = useReducer(validateReducer, getInitialState(step2Data));

	useEffect(() => onStepDataUpdated(stepData, isStepValid), [stepData, isStepValid, onStepDataUpdated]);

	const {
		dob,
		sexAtBirth,
		genderMultiSelect,
		genderMultiSelectCustomOption,
		pronounsMultiSelect,
		pronounsCustomOption,
	} = t('step2', {
		returnObjects: true,
	}) as any;

	function onValueChanged(name: ControlName) {
		return (value: Step2Data[ControlName], validationResult?: boolean) => {
			dispatch({ name, value, validationResult });
		};
	}

	const dobRef = useRef<DobRef>(null);
	const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (evt) => {
		evt.preventDefault();
		dobRef?.current?.validateDOBInput();
		onSubmit();
	};

	const customOption = genderMultiSelect.options[genderMultiSelect.options.length - 1].value;

	return (
		<form onSubmit={handleOnSubmit} noValidate>
			<Flex flexDirection="column" gap={3}>
				<DobInput
					ref={dobRef}
					autoFocus={true}
					isRequired={true}
					localization={dob.localization}
					onInputChanged={onValueChanged('dob')}
					initialValue={step2Data.dob}
					errorText={dob?.errorText}
				/>
				<Dropdown
					defaultValue={step2Data.sexAtBirth}
					{...sexAtBirth}
					isRequired={true}
					validated={false}
					onDropdownChange={onValueChanged('sexAtBirth')}
					errorText={errorText?.sexAtBirth}
				/>

				<Box
					pb={stepData?.genderMultiSelect?.includes(customOption) ? '' : 6}
					data-testid="gender-multiselect-container"
				>
					<MultiSelect
						defaultValue={step2Data.genderMultiSelect}
						{...genderMultiSelect}
						onMultiSelectChange={onValueChanged('genderMultiSelect')}
					/>
				</Box>

				{stepData?.genderMultiSelect?.includes(customOption) ? (
					<InputField
						value={step2Data.genderCustomOption}
						type="text"
						{...genderMultiSelectCustomOption}
						isRequired={true}
						onInputChange={onValueChanged('genderCustomOption')}
						errorText={errorText?.genderCustomOption}
						data-testid="gender-custom-input"
					/>
				) : null}

				<Box
					pb={stepData?.pronounsMultiSelect?.includes(customOption) ? '' : 6}
					data-testid="pronoun-multiselect-container"
				>
					<MultiSelect
						defaultValue={step2Data.pronounsMultiSelect}
						{...pronounsMultiSelect}
						onMultiSelectChange={onValueChanged('pronounsMultiSelect')}
					/>
				</Box>

				{stepData?.pronounsMultiSelect?.includes(customOption) ? (
					<InputField
						value={step2Data.pronounsCustomOption}
						type="text"
						{...pronounsCustomOption}
						isRequired={true}
						onInputChange={onValueChanged('pronounsCustomOption')}
						errorText={errorText?.pronounsCustomOption}
						data-testid="pronoun-custom-input"
					/>
				) : null}

				<Stack
					display="flex"
					flexDir={{
						base: 'column-reverse',
						md: isEditMode ? 'row-reverse' : 'row',
					}}
					justifyContent="flex-end"
					alignItems={isEditMode ? 'flex-start' : 'initial'}
					spacing={2}
				>
					<Button
						variant="tertiary"
						h={12}
						minW={{ sm: '100%', md: 140 }}
						px={8}
						py={3}
						onClick={onBackButtonClick}
						data-testid="step2-cancel-button"
					>
						{backButtonText}
					</Button>

					<Button
						type="submit"
						h={12}
						minW={{ sm: '100%', md: 140 }}
						px={8}
						py={3}
						isLoading={isSubmitButtonLoading || false}
					>
						{submitButtonText ? submitButtonText : t('cta.next')}
					</Button>
				</Stack>
			</Flex>
		</form>
	);
};
