import type { Locale } from '@/entities/routerBridge';
import { ProfileManager } from '@/features/profile';
import { setEnvironment, type EnvironmentContextParams } from '@/shared/environment';
import type { SessionContextParams } from '@/shared/session';
import { useLanguage } from '@/shared/useLanguage';
import { createEvent, createStore, restore, sample } from 'effector';
import { useUnit } from 'effector-react';
import { debug } from 'patronum';
import { useEffect, type FunctionComponent } from 'react';

const $applicationLoaded = createStore(false);
const applicationLoaded = createEvent<{ session: SessionContextParams }>();

export const applicationDidLoad = createEvent();

export const $session = createStore<SessionContextParams>({ status: 'unauthenticated' });
$session.on(applicationLoaded, (_, { session }) => session);

const setLocale = createEvent<Locale>();
export const $locale = restore(setLocale, 'en');

debug({ trace: true }, { $applicationLoaded, applicationLoaded, applicationDidLoad, $session, $locale });

sample({
	clock: applicationLoaded,
	source: $applicationLoaded,
	filter: (source) => !source,
	fn: () => true,
	target: [$applicationLoaded, applicationDidLoad],
});

export type ApplicationLoaderProps = {
	session: SessionContextParams;
	environment: EnvironmentContextParams;
};

export const ApplicationLoader: FunctionComponent<ApplicationLoaderProps> = ({ session, environment }) => {
	const { locale } = useLanguage();
	const setSessionData = useUnit(ProfileManager.setSessionData);

	useEffect(() => {
		console.info('Application Loaded');
		window.applicationDidLoad = applicationDidLoad;
		applicationLoaded({ session });
	}, [session]);

	useEffect(() => {
		setEnvironment(environment);
	}, [environment]);

	useEffect(() => {
		setLocale(locale);
	}, [locale]);

	useEffect(() => {
		if (session.status === 'authenticated') {
			setSessionData(session.access_token);
		}
	}, [session, setSessionData]);

	return <></>;
};
