export const HomepageSlug = '/';
export const MyProfileSlug = '/myProfile';
export const DeletePendingSlug = '/deletePending';
export const claimBookingSlug = '/claimBooking';
export const MetricsSlug = '/metrics';
export const AppointmentsSlug = '/appointments';
export const VerificationFailedSlug = '/verificationFailed';
export const VerificationPhoneSlug = '/verificationPhone';
export const TalkableStandAloneSlug = '/refer-a-friend';
export const VerificationNameDobSlug = '/verificationNameDob';
export const ClaimErrorSlug = '/claimError';
export const AccountCreationSlug = '/claimAccountCreation';
