import type { Locale } from '@/entities/routerBridge';
import { Button, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { CheckboxCard } from '@ldfeplatform/drx-component-library.ui.atoms.checkbox-card';
import { Dropdown } from '@ldfeplatform/drx-component-library.ui.atoms.dropdown';
import { InputField } from '@ldfeplatform/drx-component-library.ui.atoms.input-field';
import { PhoneNumberField } from '@ldfeplatform/drx-component-library.ui.atoms.phone-number-field';
import { PostalCodeField } from '@ldfeplatform/drx-component-library.ui.atoms.postal-code-field';
import { useTranslation } from 'next-i18next';
import { useEffect, useReducer, useRef, type FunctionComponent } from 'react';
import type { StepErrors } from 'utilities/profile';

type ControlName = 'street1' | 'street2' | 'city' | 'province' | 'postalCode' | 'mobileNumber' | 'marketingConsent';

export type Step3Data = {
	street1: string;
	street2?: string;
	city: string;
	province: string;
	postalCode: string;
	mobileNumber: string;
	marketingConsent: boolean;
};

export type Step3FormProps = {
	locale: Locale;
	step3Data: Step3Data;
	onStepDataUpdated: (data: Step3Data, isValid: boolean) => void;
	onSubmit: () => void;
	backButtonText: string;
	submitButtonText?: string;
	onBackButtonClick: () => void;
	isSubmitButtonLoading: boolean;
	isEditMode?: boolean;
	errorText: StepErrors;
};

type StateShape = {
	controls: Record<ControlName, { validated: boolean; isInvalid: boolean; isRequired: boolean }>;
	stepData: Step3Data;
	isStepValid: boolean;
};

type ValidationAction<T extends Step3Data = Step3Data, K extends ControlName = ControlName> = {
	name: K;
	value: T[K];
	validationResult?: boolean;
};

const isValueValid = ({ name, value, validationResult }: ValidationAction) => {
	const fieldsWithValidatedResult = ['postalCode', 'mobileNumber'];
	if (fieldsWithValidatedResult.includes(name)) {
		return validationResult && value !== undefined;
	}
	return value !== '';
};

function validateReducer(state: StateShape, { name, value, validationResult }: ValidationAction): StateShape {
	const newState = {
		...state,
		controls: {
			...state.controls,
			[name]: {
				...state.controls[name],
				isInvalid: value === '',
				validated: isValueValid({ name, value, validationResult }),
			},
		},
		stepData: {
			...state.stepData,
			[name]: value,
		},
	};
	const isStepValid = Object.values(newState.controls).every(({ isRequired, validated }) => {
		return isRequired ? validated : true;
	});
	return {
		...newState,
		isStepValid,
	};
}

const getInitialState = (stepData: Step3Data) => ({
	controls: {
		street1: { validated: false, isInvalid: false, isRequired: true },
		street2: { validated: false, isInvalid: false, isRequired: false },
		city: { validated: false, isInvalid: false, isRequired: true },
		province: { validated: false, isInvalid: false, isRequired: true },
		postalCode: { validated: false, isInvalid: false, isRequired: true },
		mobileNumber: { validated: false, isInvalid: false, isRequired: true },
		marketingConsent: { validated: false, isInvalid: false, isRequired: false },
	},
	stepData,
	isStepValid: false,
});

export const Step3Form: FunctionComponent<Step3FormProps> = ({
	step3Data,
	onStepDataUpdated,
	onSubmit,
	onBackButtonClick,
	backButtonText,
	submitButtonText,
	isSubmitButtonLoading,
	isEditMode,
	errorText,
}) => {
	const [{ stepData, isStepValid }, dispatch] = useReducer(validateReducer, getInitialState(step3Data));
	const { t } = useTranslation('createProfile');

	function onValueChanged(name: ControlName) {
		return (value: Step3Data[ControlName], validationResult?: boolean) => {
			dispatch({ name, value, validationResult });
		};
	}

	useEffect(() => onStepDataUpdated(stepData, isStepValid), [stepData, isStepValid, onStepDataUpdated]);

	const { street1, street2, city, postalCode, province, privacyPolicy, mobileNumber } = t('step3', {
		returnObjects: true,
	}) as any;

	const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = (evt) => {
		evt.preventDefault();
		onSubmit();
	};

	const autoFocusRef = useRef<HTMLInputElement>();
	useEffect(() => autoFocusRef.current?.focus(), [autoFocusRef]);

	return (
		<form onSubmit={handleOnSubmit}>
			<Flex flexDirection="column" gap={3}>
				<InputField
					value={step3Data.street1}
					type="text"
					{...street1}
					isRequired={true}
					onInputChange={onValueChanged('street1')}
					data-testid="street1-input"
					ref={autoFocusRef}
					errorText={errorText?.street1}
				/>
				<InputField
					value={step3Data.street2}
					type="text"
					{...street2}
					isRequired={false}
					onInputChange={onValueChanged('street2')}
					data-testid="street2-input"
				/>
				<InputField
					value={step3Data.city}
					type="text"
					{...city}
					isRequired={true}
					onInputChange={onValueChanged('city')}
					data-testid="city-input"
					errorText={errorText?.city}
				/>
				<Dropdown
					defaultValue={step3Data.province}
					{...province}
					isRequired={true}
					onDropdownChange={onValueChanged('province')}
					data-testid="province-input"
					errorText={errorText?.province}
				/>
				<PostalCodeField
					initialValue={step3Data.postalCode}
					type="text"
					inputLabel={postalCode.inputLabel}
					isRequired={true}
					onInputChange={onValueChanged('postalCode')}
					data-testid={'postal-code-input'}
					errorText={errorText?.postalCode}
				/>
				<PhoneNumberField
					initialValue={step3Data.mobileNumber}
					inputLabel={mobileNumber.inputLabel}
					isRequired={true}
					onInputChange={onValueChanged('mobileNumber')}
					data-testid="phone-number-input"
					errorText={errorText?.mobileNumber}
				/>
				{!isEditMode && (
					<CheckboxCard
						{...privacyPolicy}
						isChecked={step3Data.marketingConsent}
						onCheckedChanged={onValueChanged('marketingConsent')}
						helpText={
							<Text textStyle="common03.regular" color="black">
								{privacyPolicy.helpText1}
								<Link
									href={privacyPolicy.link}
									textStyle="common03.regular"
									color="uiBlue"
									textDecoration="underline"
									isExternal
								>
									{privacyPolicy.helpTextLink}
								</Link>
								{privacyPolicy.helpText2}
							</Text>
						}
					/>
				)}

				<Stack
					display="flex"
					flexDir={{
						base: 'column-reverse',
						md: isEditMode ? 'row-reverse' : 'row',
					}}
					justifyContent="flex-end"
					alignItems={isEditMode ? 'flex-start' : 'initial'}
					spacing={2}
				>
					<Button
						variant="tertiary"
						h={12}
						minW={{ sm: '100%', md: 140 }}
						px={8}
						py={3}
						onClick={onBackButtonClick}
						data-testid="step3-cancel-button"
					>
						{backButtonText}
					</Button>

					<Button
						type="submit"
						minW={{ sm: '100%', md: 140 }}
						px={8}
						py={3}
						h={12}
						isLoading={isSubmitButtonLoading}
						data-testid="step3-submit-button"
					>
						{submitButtonText ? submitButtonText : t('cta.submit')}
					</Button>
				</Stack>
			</Flex>
		</form>
	);
};
